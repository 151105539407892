<template>
  <div class="actions-content">
    <button
      @click="changeStep('prev')"
      type="button"
      class="button_outline"
      :disabled="loading"
    >
      {{ prevLabel }}
    </button>
    <button
      @click="changeStep('next')"
      :disabled="loading"
      type="button"
      class="button_outline"
    >
      {{ nextLabel }}
    </button>
  </div>
</template>
<script>
import Vue from 'vue'
import registerModel from '@/models/Register'
import swal from 'sweetalert'
import $help from '@/plugins/helpers'

export default Vue.extend({
  data: () => ({
    form: registerModel.form
  }),
  props: {
    step: Number,
    loading: Boolean
  },
  methods: {
    changeStep (type) {
      const step = type === 'prev' ? this.step - 1 : this.step + 1
      if (step > 0) {
        if (this.form.type === 0) {
          swal({
            title: 'Selecione o local',
            text: 'Para prosseguir selecione o local onde o material é gerado',
            icon: 'error'
          })
          return
        }
        if (type === 'next' && this.step === 1) {
          if (this.form.type === 1) {
            if (this.form.name === '' || this.form.cpf === '' || this.form.phone === '') {
              swal({
                title: 'Preencha todos os campos',
                text: 'Todos os campos precisam estar preenchidos',
                icon: 'error'
              })
              return
            }
            if (this.form.cpf.length < 11 || $help.cpfValidate(this.form.cpf) === false) {
              swal({
                title: 'Informe o CPF corretamente',
                text: 'Digite corretamente os números do CPF',
                icon: 'error'
              })
              return
            }
          }
          if (this.form.type === 2) {
            if (this.form.company_name === '' || this.form.fantasy_name === '' || this.form.cnpj === '') {
              swal({
                title: 'Preencha todos os campos',
                text: 'Todos os campos precisam estar preenchidos',
                icon: 'error'
              })
              return
            }
            if ($help.cnpjValidate(this.form.cnpj) === false) {
              swal({
                title: 'CNPJ Inválido',
                text: 'Informe um CNPJ válido para prosseguir',
                icon: 'error'
              })
              return
            }
          }
        }

        if (type === 'next' && this.step === 2) {
          if (this.form.zip_code === '' || this.form.district === '' || this.form.street === '' || this.form.number === '' || this.form.city_id === '' || this.form.province_id === '' || this.form.cellphone === '') {
            swal({
              title: 'Preencha todos os campos',
              text: 'Todos os campos precisam estar preenchidos',
              icon: 'error'
            })
            return
          }
        }
        if (type === 'next' && this.step === 3) {
          if (this.form.agree === false) {
            swal({
              title: 'Politica de privacidade',
              text: 'Para prosseguir com o cadastro é necessário realizar o aceite da nossa política de privacidade',
              icon: 'error'
            })
            return
          }
        }
      }

      if (step === 0) {
        this.$router.push('/auth/login')
        return
      } else if (step === 4) {
        this.register()
        return
      }

      this.$emit('update', step)
    },
    register () {
      this.$emit('register', true)
    }
  },
  computed: {
    prevLabel () {
      return this.step > 1 ? 'anterior' : 'fazer login'
    },
    nextLabel () {
      const label = this.step < 3 ? 'próximo' : 'registrar'
      return this.loading ? 'Registrando...' : label
    }
  }
})
</script>

<style scoped>
  .actions-content {
    margin: 1rem 0;
    width: 56%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0.75rem;
    right: 2.5rem;
  }

  .actions-content button {
    text-transform: capitalize;
    width: 9rem;
  }

  .actions-content button:first-child {
    color: #343a40;
    border-color: #343a40;
  }
</style>
